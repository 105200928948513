.scan-progress-container {
  &.v2 {
    height: 100%;
  }

  .back-button-container {
    position: relative;

    .back-arrow {
      margin-right: 20px;
    }

    .invert-button:hover {
      path {
        fill: var(--theme-third-color-invert);
      }
    }
  }

  .scan-progress-animation-container {
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;

    .pointer {
      fill: var(--theme-text-color);
    }

    .scan-progress-subtitle {
      margin-top: -120px;
    }
  }
}
