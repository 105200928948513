@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.loading-spinner {
  animation: spin 2s linear infinite;
  path {
    fill: var(--theme-third-color);
  }
}
