.static-header {
  position: static;
  top: 0;
  width: 100%;
  border-bottom: 2px solid var(--theme-text-color);
  padding: 1rem;
  // margin-bottom: 1.5rem;
  display: flex;
  flex-flow: row;

  &.v2 {
    border-bottom: 1px solid var(--separator-color-v2);
  }

  .left-container {
    display: flex;
    flex-flow: column;
    margin-left: 1rem;
    align-items: center;

    .dashboard-name {
      font-size: 1rem;
    }
  }

  .right-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem;

    & > div {
      margin: 0 0.25rem;
    }
  }

  @include respond-below(tablet) {
    .right-container {
      flex-flow: column;
    }
  }
}
