.ts-questions-outer-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ts-questions-inner-heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ts-questions-percent-complete-box {
  margin: 0 0.5rem;
  padding: 0.25rem;
  background-color: var(--disabled-background);
  font-size: (7/8) * 1rem;
}

.troubleshooting-questions {
  .troubleshooting-nav {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
}

.troubleshooting-header {
  display: flex;
  align-items: center;
}
