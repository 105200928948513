.expandable-component-heading {
  margin: 0.5rem 0;
  padding: 0.25rem 0;
  font-size: 1.25rem;
}

.expandable-component-content {
  overflow: hidden;
  transition: 0.5s ease-out;
}
