.streaming-quality-V2 {
  max-width: 100%;
  > p {
    margin: 0;
    color: var(--third-text-color);
  }

  .streaming-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 1rem;
    max-width: 560px;
    padding-top: 0.5rem;
  }

  .quality-image-wrapper {
    height: 34px;
  }

  .quality-image {
    width: 34px;

    path {
      fill: var(--theme-text-color);
    }

    .browsing-path {
      fill: var(--theme-bg-color);
      stroke: var(--theme-text-color);
    }

    text {
      fill: var(--theme-text-color);
    }
  }

  .text {
    font-size: 12px;
    font-family: $primary-font-mono;
    margin: 0;
  }

  .seperator {
    display: none;
    width: 1px;
    height: 151px;
    background-color: rgba(184, 190, 199, 0.48);
    position: absolute;
    top: 20px;
    right: 25px;
  }
}
