.expert-modal {
  .mask {
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(116, 116, 116, 0.9);
    z-index: 2;
    transition: opacity 0.2s;
    pointer-events: none;
  }
  .content-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    overflow: auto;
    transition: transform 0.5s ease-in-out;
    z-index: 3;
    padding: 1rem;
  }
  .flex-container {
    height: 100%;
    display: flex;
    padding: 0 2em;
    align-items: center;
    justify-content: center;
  }
  .content {
    padding: 2em 1em;
    width: 75%;
    margin: auto 0;
    max-width: 800px;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 #666666;
    position: relative;
  }
  .close-button {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 1em;
    right: 0.5em;
    font-size: 14px;
    text-decoration: none;
    svg {
      margin-left: 0.5em;
      position: relative;
      bottom: 3px;
    }
  }
}
