// Atomic UI
@import '~@npmsoluto/asurion-design-assets-asurion/asurion.css';

@font-face {
  font-family: Apercu-Pro-Light;
  src: url('./fonts/Apercu-Pro-Light.otf') format('opentype');
}

@font-face {
  font-family: Apercu-Pro-Mono;
  src: url('./fonts/Apercu-Pro-Mono.otf') format('opentype');
}

@font-face {
  font-family: Apercu-Pro-Regular;
  src: url('./fonts/Apercu-Pro-Regular.otf') format('opentype');
}

@import './variables.scss';

// Styles
body {
  font-family: $primary-font;
  color: var(--primary-text-color);
  background-color: var(--theme-bg-color);
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  overflow-x: hidden;
  transition: background-color 0.4s ease, color 0.4s ease;
}

.border-bottom {
  border-bottom: 1px solid var(--seperator-color) !important;
}

.path-fill-white {
  path {
    fill: $white;
  }
}

// Media Queries
@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}

.icon-left {
  svg {
    margin-right: 0.5rem;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

@import './mixins.scss';
// Importing all the scss files at the END
@import './imports.scss';
