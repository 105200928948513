.grid-container {
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  max-width: 1600px;
  margin: auto;
}

.grid-results-column {
  grid-column-end: span 2;

  .border-bottom {
    border-color: var(--separator-color-v2) !important;
  }
}

@media (max-width: 959px) {
  .grid-container {
    grid-template: auto auto / auto;
    padding: 0;
  }

  .grid-results-column:not(.scan-results-column) {
    padding: 0 1rem;
  }
}

@media (max-width: '500px') {
  .grid-results-column:not(.scan-results-column) {
    padding: 0;
  }
}

@media (min-width: 960px) {
  .grid-results-column {
    grid-column-end: span 1;
  }
}
