.language-selector {
  width: 100px;
  position: absolute;
  top: 0px;
  right: 140px;

  .form-control {
    background-color: var(--theme-bg-color);
    color: var(--theme-text-color);
    height: 30px;
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .language-selector {
    top: 23px;
  }
}