.thanks-for-feedback {
  display: flex;
  flex-direction: column;
  height: 240px;
  align-items: center;
  justify-content: center;
}

.feedback-thumbs {
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;

  *:first-child {
    margin-left: 0;
  }
}

.feedback-text-form {
  margin-bottom: 0.5rem;
  width: 80%;
}


@media (max-width: '500px') {
  .feedback-text-form {
    width: 98%;
  }
}

.feedback-input {
  width: 100%;

  textarea {
    padding: 0.5rem;
    min-height: 160px;
    width: 100%;
    background-color: var(--textarea-bg-color);
    color: var(--primary-text-color);
    margin-left: 2px;

    &:focus {
      outline-color: var(--textarea-outline-color--focus);
      outline-style: solid;
      outline-width: 2px;
    }
  }
}

.feedback-subheading {
  margin-bottom: 0.5rem;
}

.feedback-prompt {
  margin-top: 0.25rem;
  margin-bottom: 1.5rem;
  color: var(--dark-grey);
}
