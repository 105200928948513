.broadband-information {
  padding-bottom: 1rem;
  .broadband-container {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    & > div {
      margin-right: 1.5rem;
    }
  }
  .bandwidth-usage-bar {
    flex-grow: 2;
    min-width: 300px;
  }
}

.upload-download-speeds {
  display: flex;
  flex-flow: row wrap;
  column-gap: 20px;
  row-gap: 20px;
}
