.network-diagnosis {
  .no-network-issues {
    padding-bottom: 1rem;
  }

  .issues-found {
    padding: 0 1rem;
    font-size: 1rem;
    color: $bad;
    font-weight: bold;
  }
}
