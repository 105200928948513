.network-devices-section {
  font-family: $secondary-font;
  padding-bottom: 3rem;

  .row {
    margin-left: 0;
  }

  .connected-devices-text {
    font-size: 1.25rem;
  }

  .connected-devices {
    margin-right: 0;
    font-size: 14px;
    align-items: center;
    color: var(--third-text-color);

    &.v2 {
      justify-content: space-between;

      .icons {
        margin-left: 0;
      }
    }

    .icons {
      span {
        font-size: 16px;
      }
    }
  }

  .icons {
    display: flex;
    align-items: center;
    margin-left: auto;

    span,
    .graph-icon {
      margin-right: 1rem;
    }

    .graph-icon,
    .list-icon {
      cursor: pointer;

      &.inactive {
        path {
          fill: var(--devices-icon-inactive);
        }
      }

      &.active {
        path {
          fill: var(--devices-icon-active);
        }
      }
    }
  }

  .no-devices-found {
    background-color: rgba(216, 216, 216, 0.35);
    height: 117px;
    line-height: 117px;
    width: 100%;
    font-size: 16px;
    border: solid 1px #e0e4ef;
    padding-left: 1rem;

    span {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      font-weight: 300;
    }

    &.v2 {
      margin-top: 0.5rem;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 76px;

      span {
        font-size: (7 / 8) * 1rem;
      }
    }
  }

  .network-devices-graph-container {
    margin: 1rem 0;

    > .row:nth-child(odd) {
      background-color: var(--bg-color-odd);
      margin-bottom: 2px;
    }

    > .row:nth-child(even) {
      background-color: var(--bg-color-even);
      margin-bottom: 2px;
    }

    .no-router-class {
      margin: 5px;
      margin-top: 18px;
    }

    .category-wrapper-top-div,
    .category-wrapper-bottom-div {
      width: 85px;
      border-right: 1px solid $good;
      border-style: solid;
      border-width: 0 1px 0 0;
      margin: -10px 0;
    }

    .category-block {
      margin: 10px;
      margin-right: 0;
      min-height: 50;
      align-items: 'center';

      .items-group {
        align-items: stretch;
        flex-basis: auto;
        flex-direction: column;
        flex-shrink: 0;
        background-color: var(--tile-bg-color);
        border-color: var(--tile-border-color);
        border-radius: 20px;
        box-shadow: 0 1px 2px 0 rgba(150, 161, 177, 0.2);
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        color: inherit;
        font: inherit;
        height: 75px;
        list-style: none;
        margin: 0px;
        min-height: 0px;
        min-width: 0px;
        opacity: 0.8;
        padding: 0px;
        position: relative;
        text-align: inherit;
        text-decoration: none;
        width: 225px;

        .inner-box {
          align-items: stretch;
          flex-basis: auto;
          flex-direction: row;
          flex-shrink: 0;
          background-color: transparent;
          box-sizing: border-box;
          color: inherit;
          font: inherit;
          list-style: none;
          margin: 8px 0 4px 8px;
          min-height: 60px;
          min-width: 0px;
          padding: 0px;
          position: relative;
          text-align: inherit;
          text-decoration: none;

          .left-section {
            width: 40px;
            margin: 0 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            .wifi-band {
              font-weight: '600';
              font-size: 10px;
              text-align: 'center';
              color: #53c9fb;
              padding-left: 11px;
            }
          }

          .right-section {
            border-left: 1px solid var(--tile-section-seprator);
            border-top-style: solid;
            border-right-style: solid;
            border-bottom-style: solid;
            border-top-width: 0px;
            border-right-width: 0px;
            border-bottom-width: 0px;
            box-sizing: border-box;
            float: right;
            list-style: none;
            margin: 0 0 0 5px;
            min-height: 0px;
            min-width: 0px;
            padding: 0px 0px 0px 6px;
            position: relative;
            text-decoration: none;
            width: 140px;
            font-size: 12px;

            .name {
              color: var(--theme-text-color);
              margin-bottom: 2px;
              font-family: $primary-font;
              font-size: 14px;
            }

            .name-common {
              color: var(--theme-text-color);
              font-family: $primary-font-light;
            }

            .icons-box {
              justify-content: space-between;

              .wired-icon {
                width: 25%;
                float: left;
              }

              .signal-strength-icon {
                width: 75%;
                display: inline;
              }

              .rssi-wrapper {
                margin-top: 5px;
                width: 75%;
                justify-content: flex-end;
                align-items: baseline;
              }
            }
          }

          .router {
            margin-top: 10px;
          }
        }
      }
    }

    .column-wrapper {
      align-items: center;
    }
  }

  .third-device-column {
    margin-left: 0;
  }

  .device-column {
    .firstcolumn-wrapper {
      width: 60px;
      height: 74px;
    }

    .secondcolumn-wrapper {
      width: 320px;
      height: 74px;
    }

    .category-block {
      margin-left: 0;
    }

    .horizontal-line {
      border-style: solid;
      border-width: 1px 0px 0px;
    }
  }

  .rssi-wrapper {
    float: right;
    margin-top: -2px;

    .rssi-signal {
      width: 7px;
      border-width: 0 0 0 0.5px;
      border-color: var(--theme-bg-color);
      border-style: solid;
      background-color: var(--wifi-signal-base-color);
    }
  }

  .network-devices-list-view {
    .device-list-container {
      display: flex;
      flex-wrap: wrap;

      // To have a 1rem gap in between, but flush with edges
      margin-left: -0.5rem;
      margin-right: -0.5rem;

      > * {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  .category-icons {
    path {
      fill: var(--theme-text-color);
    }

    tspan {
      fill: var(--theme-text-color);
      font-family: $primary-font-mono;
    }

    .unknown-rect {
      fill: var(--theme-bg-color);
    }
  }
}
