.scan-failed-container {
  .scan-failed-animation-container {
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;

    .pointer {
      fill: var(--theme-color-invert);
    }

    .scan-failed-subtitle {
      text-align: center;
    }

    button {
      margin-top: 1em;
    }
  }
}
