.pin-page {
  &__subtitle {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
    margin-top: 6rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
  &__subheader {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
    margin: 2rem 0;
    font-size: 1.2rem;
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 1rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
  &__input_field {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 44px;
    height: 60px;
    margin: 0 5px;
    font-size: 24px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: var(--header-color);
    background-color: var(--theme-bg-color);
    caret-color: var(--header-color);
    color: var(--header-color);
  }
  &__button_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
  }
  &__warning_banner {
    position: absolute;
    width: 900px;
    height: 55px;
    left: calc(50% - 450px);
    top: 110px;
    background: $warning;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    &__close_button {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 17px;
      bottom: calc(50% - 8px + 2px);
      padding: 0;
      cursor: pointer;

      svg {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.back-button-container {
  position: relative;

  .back-arrow {
    margin-right: 20px;
  }

  .invert-button:hover {
    path {
      fill: var(--theme-third-color-invert);
    }
  }
}

.asurion-scan-content-step {
  width: 70%;

  img {
    width: auto;
    height: auto;
  }
}

.asurion-scan-header {
  width: 70%;
}
