.login-page {
  background-color: var(--theme-bg-color);
  color: var(--primary-text-color);

  .sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .button-margin {
    margin: 2rem 0;
  }
  .asurion-logo {
    margin: 1rem 0;
  }
}