.subheader-wrapper {
  font-family: $secondary-font;
  margin: 22px 0;
}

.subheader {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;

  .session-dropdown {
    flex-grow: 1;
    padding-bottom: 0.5rem;
  }

  .matching-button {
    padding-right: 1rem;
  }
}
