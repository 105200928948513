.scroll-buttons-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  font-size: 0.75em;

  .scroll-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      border: 1px solid #999999;
      background-color: transparent;

      &:disabled {
        background-color: transparentize(#000000, 0.9); // TODO: change
      }

      &:nth-of-type(1) {
        border-radius: 3px 0 0 3px;
        border-right: 0;
      }

      &:nth-of-type(2) {
        border-radius: 0 3px 3px 0;
        border-left: 0;
      }
    }
  }

  .scroll-buttons-label {
    margin-left: 15px;
  }

  .vertical-divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 1px;
    background-color: #999999;
  }
}
