.pre-scan-page {
  &__title {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
    margin: 0 1rem;
    font-size: 2rem;
  }
  &__content {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  &__steps {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
    padding-bottom: 4rem;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }
}
