.a-accordion {
  margin: 1rem 0;


  .accordion-button {
    color: var(--accordion-label-color);
    background-color: var(--accordion-background-color, $white);
    cursor: pointer;
    padding: 1rem;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 1rem;
    transition: 0.4s;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--accordion-border-color);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--accordion-border-color);
    border-left: none;
    border-right: none;

    .text-container {
      display: flex;
      justify-content: center;

      .icon-left {
        margin-right: 0.5rem;
      }

      .text {
        flex: 1;
      }
    }

    &.active {
      .a-chevron {
        transition: 0.2s;
        transform: rotate(180deg);
      }
    }

    &:hover {
      background-color: var(--accordion-background-color-hover, #f3f3f3);
    }
  }

  .panel {
    padding: 1rem;
    display: none;
    background-color: transparent;
    overflow: hidden;
  }
}
