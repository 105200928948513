@keyframes textChanged {
  0% {
    background-color: var(--theme-bg-color);
  }

  20% {
    // !TODO: Talk with Nick about background for switching in light mode
    background-color: #f0fff9;
  }

  100% {
    background-color: var(--theme-bg-color);
  }
}

.wifi-wizard-issue {
  padding-bottom: 1rem;
  margin-bottom: 1.25rem;

  &.border-bottom {
    border-color: var(--separator-color-v2) !important;
  }

  p {
    color: var(--dark-grey);
    line-height: 150%;
  }
}

.wifi-wizard-solution {
  animation: textChanged 1s 1;

  p {
    font-size: 1.25rem;
    color: var(--dark-grey);
    line-height: 150%;
  }

  a {
    color: var(--theme-color);
  }

  .wifi-wizard-accordion .text {
    font-size: 2rem;
  }
}

.wifi-wizard-solutions-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  span {
    font-size: (7/8) * 1rem;
    color: var(--dark-grey);
  }
}

.wifi-wizard-accordion {
  padding: 0.25rem 0;
  margin: 0;

  .accordion-button {
    border: unset;
    padding: 0.5rem 0;
  }

  .text-container {
    align-items: center;
  }

  .panel {
    padding: 0.5rem 1rem;
  }
}

.wifi-wizard-subheading {
  margin: 0;
  font-size: 1rem;
}

.wifi-wizard-will-use-solution {
  border: 1px solid var(--disabled-background);
  background: var(--accordion-background-color-hover);
  border-radius: 3px;
  padding: 1rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;

  span {
    margin-right: 1rem;
  }
}

.recommendation-button {
  padding: 0;
  margin: 0;

  &.primary-ghost  {
    path {
      fill: var(--button-border-color--primary-ghost);
    }
  }

  &.primary-ghost:hover {
    path {
      fill: var(--button-color--primary-ghost--hover);
    }
  }
}
