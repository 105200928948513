.no-data-found {
  text-align: center;
  padding-top: 150px;

  h2 {
    font-size: 22px;
    margin-top: 20px;
    color: var(--theme-text-color);
  }

  .alert-triangle,
  .cloud-block {
    path {
      fill: var(--theme-text-color);
    }
  }
}