@font-face {
    font-family: Apercu;
    font-weight: bolder;
    src: url('./fonts/Apercu-Black-Pro.ttf');
}

@font-face {
    font-family: Apercu;
    font-weight: bold;
    src: url('./fonts/Apercu-Bold-Pro.ttf');
}

@font-face {
    font-family: Apercu;
    font-weight: 200;
    src: url('./fonts/Apercu-Light-Pro.ttf');
}

@font-face {
    font-family: Apercu;
    font-weight: normal;
    src: url('./fonts/Apercu-Regular-Pro.ttf');
}

@font-face {
    font-family: ApercuMono-Light-Pro;
    src: url('./fonts/ApercuMono-Light-Pro.ttf');
}

@font-face {
    font-family: ApercuMono-Regular-Pro;
    src: url('./fonts/ApercuMono-Regular-Pro.ttf');
}
@font-face {
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/icomoon.eot');
    src: url("./fonts/icomoon.eot?#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.woff2") format("woff2"),
    url("./fonts/icomoon.ttf") format("truetype"),
    url("./fonts/icomoon.woff") format("woff"),
    url("./fonts/icomoon.svg") format("svg");
}
:root {
    --primary-font-family: "Apercu", sans-serif;
    --secondary-font-family: "Apercu", sans-serif;

    /* Button CSS variables for themes */
    --button-font-family: var(--primary-font-family);
    /*--button-background-color : #0057B8;
    --button-border-color: #0057B8;
    --button-color: #ffffff;
    --button-background-color--hover: #007AE2;
    --button-border-color--hover : #007AE2;
    --button-color--hover: #ffffff; */

    /* Button Primary Styles */
    /* --button-background-color--primary: transparent;
    --button-border-color--primary: #0057B8;
    --button-color--primary: #0057B8;
    --button-background-color--primary--hover: transparent;
    --button-border-color--primary--hover: #000000;
    --button-color--primary--hover: #000000; */

    /* Button Secondary Styles */
    /* --button-background-color--secondary: #ffffff;
    --button-border-color--secondary: #ffffff;
    --button-color--secondary: #0057B8;
    --button-background-color--secondary--hover: #E7E7E8;
    --button-border-color--secondary--hover: #E7E7E8;
    --button-color--secondary--hover: #0057B8; */

   /* --button-background-color--disabled
    --button-background-color--disabled--primary
    --button-background-color--disabled--primary-ghost
    --button-background-color--disabled--secondary
    --button-background-color--disabled--secondary-ghost
    --button-border-color--disabled
    --button-border-color--disabled--primary
    --button-border-color--disabled--primary-ghost
    --button-border-color--disabled--secondary
    --button-border-color--disabled--secondary-ghost
    --button-color--disabled
    --button-color--disabled--primary
    --button-color--disabled--primary-ghost
    --button-color--disabled--secondary
    --button-color--disabled--secondary-ghost */

    /* Button Icon Styles*/
    /* --button-icon-margin-right
    --button-icon-margin-left */

    /* CSS variables for themes */
    /* --icon-margin-right
    --icon-margin-left
    --icon-height
    --icon-vertical-align */

    /* Input Styles*/
    --input-error-border: 1px solid #C70032;
    --input-error-text-color: #C70032;
    --input-border: 1px solid #aaafb4;
    --input-border-radius: 0;
    --input-hover-border: 1px solid #000;
    --input-focus-border: 1px solid #8223d2;
}

body {
    font-family: var(--primary-font-family);
}