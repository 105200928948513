.pre-scan-component-heading {
  display: flex;
  justify-content: space-between;

  .a-chevron {
    transition: transform 0.2s;
  }

  .active {
    .a-chevron {
      transform: rotate(180deg);
    }
  }
}

.pre-scan-component-steps {
  margin: 1rem 0;
}

.pre-scan-component-step {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;

  .step-title {
    margin-bottom: 0.5rem;
  }

  .step-content-img-container {
    display: flex;
    justify-content: center;
  }

  .vwz-scan-button {
    margin-top: 1rem;
  }

  .pin-page {
    &__input_container {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }

    &__input {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0.25rem;
      justify-content: flex-start;
    }

    &__input_field:first-child {
      margin-left: 0;
    }

    &__warning {
      color: #6e767d;
    }
  }
}

@media (max-width: 959px) {
  .scanning-v2-container {
    padding-top: 5rem;
  }
}

.scanning-v2-container {
  padding-top: 10rem;
  padding-bottom: 5rem;
  margin: 0 auto;
  width: min-content;

  & *:first-child {
    margin-bottom: 3rem;
  }

  .scanning-v2-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .scanning-v2-top-text {
    font-size: 1.5rem;
  }

  .scan-failed-animation-container,
  .scan-failed-subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .scan-failed-animation-container {
    padding: 0 1rem;
  }

  .scan-failed-subtitle {
    padding-bottom: 1rem;
    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1rem;
    }
  }
}

@keyframes loading-animation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-7px);
  }
}

.scanning-v2 {
  overflow: visible;

  &.scanning-in-progress {
    .scanning-element {
      * {
        stroke: var(--theme-asurion-color);
      }

      circle {
        fill: var(--theme-asurion-color);
      }

      animation-name: loading-animation;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      &:nth-of-type(1) {
        animation-delay: 0s;
      }
      &:nth-of-type(2) {
        animation-delay: 0.25s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.5s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.75s;
      }
      &:nth-of-type(5) {
        animation-delay: 1s;
      }
    }
  }
}
