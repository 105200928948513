.react-toggle-track {
  width: 121px;
  height: 30px;
  background-color: var(--toggle-bg-color) !important;
  color: var(--toggle-text-color);
}

.react-toggle-thumb {
  width: 25px;
  height: 25px;
  border: none;
  top: 2px;
  right: 2px;
  left: 2px;
  box-shadow: none !important;
}

.react-toggle--checked {
  .react-toggle-thumb {
    left: auto;
  }

  .react-toggle-track {
    background-color: var(--toggle-bg-color) !important;
  }
}

.react-toggle-track-check {
  width: 67px;
  height: 0;
  font-family: $primary-font-mono;
  font-size: 12px;
  left: 12px;
}

.react-toggle-track-x {
  width: 75px;
  height: 0;
  font-family: $primary-font-mono;
  font-size: 12px;
  right: 13px;
}
