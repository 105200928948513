.initiate-scan-container {
  text-align: center;
  padding-top: 20px;

  .initiate-scan-button-container {
    padding-left: -25px;

    .initiate-scan {
      background-color: var(--dropdown-bg-color);
      height: 50px;
      mix-blend-mode: normal;
      opacity: 1;
      border: 1px solid var(--theme-color);
      box-sizing: border-box;
      box-shadow: 0 2px 2px 0 var(--dropdown-box-shadow);
      border-radius: 33px;
      text-align: center;
      color: var(--initiate-scan-button-text-color);
      padding: 0 20px;
    }

    button:disabled {
      background-color: var(--dropdown-bg-color);
      mix-blend-mode: normal;
      opacity: 0.6;
      border: 1px solid var(--initiate-scan-button-disabled-text-color);
      box-sizing: border-box;
      box-shadow: 0 2px 2px 0 var(--dropdown-box-shadow);
      border-radius: 33px;
    }
  }

  .text {
    font-size: 18px;
    letter-spacing: -0.1px;
    margin-top: 1rem;
    text-align: center;
  }

  .request_error {
    color: var(--initiate-scan-button-error-text-color);
  }

}