// Reference for this CSS https://css-tricks.com/custom-list-number-styling/

ol.connected-list {
  counter-reset: list-counter;
}
ol.connected-list li {
  position: relative;
  margin-bottom: 0;
  padding: 0 0.5rem 1rem;
  list-style: none;
  counter-increment: list-counter;
}

// The styled centered numbers
ol.connected-list li:before {
  color: var(--theme-bg-color);
  content: counter(list-counter);
  font-family: $primary-font;
  font-size: 12px;
  position: absolute;
  top: 8px;
  --size: 16px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  background: var(--theme-text-color);
  border-radius: 50%;
  text-align: center;
}

// The lines
ol.connected-list li:after {
  content: '';
  position: absolute;
  left: -18px; /* adjust manually */
  top: 28px;
  border-left: 1px solid var(--theme-text-color);
  height: calc(100% - 8px);
}

ol.connected-list li:last-child:after {
  height: 0px;
}

.expand-results-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  outline: none;
  color: var(--primary-text-color);
  margin-right: 1rem;

  &:focus {
    outline: none;
  }

  .a-chevron {
    transform: rotate(90deg);
    margin-right: 0.25rem;

    path {
      fill: var(--theme-color);
    }
  }
}

.scan-results-column {
  border-left: 1px var(--separator-color-v2) solid;
}

@media (max-width: '959px') {
  .scan-results-column {
    order: -1;
    border-left: none;
    border-bottom: 1px solid var(--separator-color-v2);
  }
}

@media (max-width: '500px') {
  ol.connected-list li .base-button {
    font-size: 0.8rem;
  }
}

.scan-component {
  padding: 1rem 1.5rem 2rem 1.5rem;

  &.small-bottom-padding {
    padding-bottom: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--separator-color-v2);
  }
}

@media (max-width: '400px') {
  .scan-component {
    padding: 1rem 1rem 2rem 1rem;
  }
}

h1.scan-subheading {
  font-size: 1.25rem;
  line-height: 1.5;
}

.scan-subheading {
  h1 {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  margin-top: 0;
  margin-bottom: 1rem;
}

.homeplus-scan-instructions-checkbox-container {
  padding: 1rem;
}
