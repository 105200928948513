svg.device-icons {
  &,
  path,
  g,
  polygon {
    fill: var(--theme-text-color);
  }

  .unknown-rect {
    fill: var(--theme-bg-color);
  }

  .override-color {
    fill: white;
  }

  .override-icon-good {
    fill: #008662;
  }

  .override-icon-poor {
    fill: #edb800;
  }
}
