@mixin footer {
  width: 100%;
  bottom: 0;
  position: fixed;
}

.dashboard-footer {
  --button-border-color--primary-ghost: white;
  --button-border-color--primary-ghost: white;
  background-color: var(--theme-asurion-color);
  @include footer;
}

.disabled-dashboard-footer {
  background-color: var(--disabled-background);
  @include footer;
}
