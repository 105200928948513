.pre-scan-step {
  margin: 1rem 0;
  width: 100%;
  padding: 0 1rem;

  &:first-child:not(:only-child) {
    margin: 2rem 0 0 0;
  }

  &:last-child:not(:only-child) {
    margin: 0 0 2rem 0;
  }

  &__title {
    font-weight: bold;
    margin: 1rem 0;
  }
  &__content {
    font-size: 1.25rem;
  }

  &__children {
    margin: 1rem 0;
  }
}
