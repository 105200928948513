.upload-download-speeds {
  .speed-container {
    display: flex;
    justify-content: left;

    .wrapper {
      margin-right: 1rem;

      .speed {
        font-family: $primary-font-mono;
        font-size: 30px;
        color: var(--theme-color);
        margin: 0;
        float: left;
        padding-top: 0.5rem;
      }
      .stack {
        padding-left: 0.5rem;
        display: flex;
        align-items: center;
        flex-flow: column;
      }

      .unit-of-measure {
        font-family: $secondary-font;
        font-size: 0.75rem;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      .arrow {
        width: 13px;
        height: 24px;
      }

      .text {
        margin-top: 0.5rem;
        font-family: $primary-font;
        font-size: 1rem;
        text-align: left;
      }
    }

    .upload {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
}

.upload-download-arrow {
  * {
    stroke: var(--primary-text-color);
  }
}
