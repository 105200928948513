.arrow-horizontal-left {
  path {
    fill: var(--theme-third-color);
  }
}

.arrow-horizontal-right {
  path {
    fill: $white;
  }
}
