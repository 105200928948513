.device-view {
  border: 1px solid #c0c1da;
  margin: 0.5rem 0.5rem;
  display: flex;
  flex-flow: row;
  padding: 0.5rem;

  .description {
    display: flex;
    flex-flow: column;
    align-content: center;
    margin: 0 0.5rem;

    .device-name {
      width: 150px;
      font-size: 1rem;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .device-make {
      width: 150px;

      display: block;
      font-size: 0.75rem;
    }
  }

  &.v2 {
    border: 1px solid #f0f0f5;
    margin: 0.5rem 0;
    justify-content: space-between;
    padding: 1.25rem 1rem;

    .description {
      margin: 0 1rem;

      .device-name {
        font-size: 0.75rem;
      }
    }
  }
}
