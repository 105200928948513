.wifi-gray {
  --color-top-wave: var(--wifi-signal-base-color);
  --color-middle-wave: var(--wifi-signal-base-color);
  --color-bottom-wave: var(--wifi-signal-base-color);
  --color-base-circle: var(--wifi-signal-base-color);

  &.best-wifi {
    --color-top-wave: var(--best-wifi-color);
    --color-middle-wave: var(--best-wifi-color);
    --color-bottom-wave: var(--best-wifi-color);
    --color-base-circle: var(--best-wifi-color);
  }
  &.better-wifi {
    --color-middle-wave: var(--better-wifi-color);
    --color-bottom-wave: var(--better-wifi-color);
    --color-base-circle: var(--better-wifi-color);
  }
  &.average-wifi {
    --color-bottom-wave: var(--average-wifi-color);
    --color-base-circle: var(--average-wifi-color);
  }
  &.weak-wifi {
    --color-base-circle: var(--weak-wifi-color);
  }
}
