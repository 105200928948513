.internet-services-V2 {
  > p {
    color: var(--third-text-color);
  }

  .internet-services-column {
    display: grid;
    grid-row-gap: 1.5rem;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(auto-fill, 102px);
    padding-top: 0.5rem;
  }

  .holder {
    display: grid;
    grid-column-gap: 9px;
    grid-template-columns: min-content auto;
    align-items: center;

    // the logo
    svg:last-of-type {
      height: 20px;
    }
  }

  .text {
    color: var(--primary-text-color);
    font-size: 1rem;
    float: left;
    margin-left: 1rem;
    margin-bottom: 0px;
    font-family: $primary-font;
    text-transform: capitalize;
  }
}
