.network-quality {
  p {
    font-family: $secondary-font;
    font-size: 14px;
  }

  .streaming-quality {
    > p {
      margin: 0;
      color: var(--third-text-color);
    }

    .quality-image-wrapper {
      height: 34px;
    }

    .quality-image {
      width: 34px;

      path {
        fill: var(--theme-text-color);
      }

      .browsing-path {
        fill: var(--theme-bg-color);
        stroke: var(--theme-text-color);
      }

      text {
        fill: var(--theme-text-color);
      }
    }

    .quality-icon {
      width: 24px;
      margin-top: 5px;
    }

    .text {
      font-size: 12px;
      font-family: $primary-font-mono;
    }

    .icon-holder {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      margin: 12px auto;
      color: $black;

      &.good {
        background-color: $good;
      }

      &.poor {
        background-color: $low;
      }

      &.nodata {
        background-color: $default;
      }

      span {
        font-family: $primary-font-mono;
        font-size: 18px;
      }
    }

    .seperator {
      display: none;
      width: 1px;
      height: 151px;
      background-color: rgba(184, 190, 199, 0.48);
      position: absolute;
      top: 20px;
      right: 25px;
    }
  }

  .internet-services {
    > p {
      color: var(--third-text-color);
    }

    .holder {
      display: flex;
      align-items: center;
    }

    .circle {
      min-width: 72px;
      float: left;

      span {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin: 2px;
        background-color: $default;
      }

      &.fill-4 {
        span {
          background-color: $good;
        }
      }

      &.fill-3 {
        span:nth-child(-n + 3) {
          background-color: $moderate;
        }
      }

      &.fill-2 {
        span:nth-child(-n + 2) {
          background-color: $low;
        }
      }

      &.fill-1 {
        span:first-child {
          background-color: $bad;
        }
      }
    }

    .text {
      color: var(--primary-text-color);
      font-size: 18px;
      float: left;
      margin-left: 10px;
      margin-bottom: 5px;
      font-family: $primary-font;
      text-transform: capitalize;
    }
    .icon-holder {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      color: $black;

      &.good {
        background-color: $good;
      }

      &.poor {
        background-color: $low;
      }

      &.nodata {
        background-color: $default;
      }

      span {
        font-family: $primary-font-mono;
        font-size: 18px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .network-quality {
      .streaming-quality {
        .seperator {
          display: block;
        }
      }
    }
  }

  @media only screen and (max-width: 393px) {
    .network-quality {
      .streaming-quality {
        .row {
          .col:nth-child(4) {
            padding-left: 4.5rem;
          }

          .col:nth-child(5) {
            margin-right: 3rem;
          }
        }
      }
    }
  }
}
