.bandwidth-usage {
  color: var(--secondary-text-color);

  .bandwidth-container {
    display: flex;
    flex-flow: column;
  }

  .progressbar {
    height: 16px;
    width: 80%;
    background-color: $max-offered;
    margin: 100px 30px;
    font-family: $primary-font-mono;
    border-radius: 16px;

    .value {
      display: block;
      position: relative;
      height: 100%;
      float: left;
    }

    .progress-bar-usage {
      background-color: $usage;
      border-radius: 16px 0 0 16px;

      div {
        position: absolute;
        right: -23px;
        top: -68px;
        line-height: 20px;
        text-align: center;

        p {
          margin: 0; // ! Bootstrap override
        }

        .number {
          font-size: 2rem;
        }

        .mbps {
          font-size: 0.75rem;
        }

        .img {
          width: 13px;
          height: 24px;
        }
      }
    }

    .plan-max {
      background-color: $plan-max;

      div {
        position: absolute;
        right: -14px;
        bottom: -58px;
        line-height: 16px;
        text-align: center;

        p {
          margin: 0;
        }

        .number {
          font-size: 22px;
        }

        .mbps {
          font-size: 10px;
        }

        .img {
          width: 13px;
          height: 24px;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }

    .available-max {
      border-radius: 0 16px 16px 0;
    }

    .min {
      position: relative;
      text-align: center;

      div {
        position: absolute;
        top: 5px;
        left: -25px;
        line-height: 12px;

        p {
          margin: 0;
        }

        .number {
          font-size: 16px;
        }

        .mbps {
          font-size: 10px;
        }
      }
    }

    .max {
      position: relative;
      text-align: center;

      div {
        position: absolute;
        top: 5px;
        right: -30px;
        line-height: 12px;

        p {
          margin: 0;
        }

        .number {
          font-size: 16px;
        }

        .mbps {
          font-size: 10px;
        }
      }
    }
  }

  .indicators {
    display: flex;
    flex-flow: column;
    font-size: 0.75rem;
    font-family: $primary-font-mono;
    margin-right: 10px;

    p {
      float: left;
    }

    .indicator {
      height: 14px;
      width: 14px;
      margin: 0 8px 0 0;
      border-radius: 50%;
    }

    .usage {
      .indicator {
        background-color: $usage;
      }
    }

    .plan-max {
      .indicator {
        background-color: $plan-max;
      }
    }

    .available-max {
      .indicator {
        background-color: $max-offered;
      }
    }
  }

  .no-data {
    .progressbar .usage p {
      right: -18px;
      top: -55px;
      font-size: 10px;
      line-height: 10px;

      span {
        font-size: 22px;
      }
    }
  }
}
