.session-dropdown {
  padding-right: 2.5rem;
  display: flex;
  flex-wrap: nowrap;

  .dropdown-toggle {
    width: 270px;
    height: 46px;
    color: var(--primary-text-color);
    background-color: var(--dropdown-bg-color);
    font-weight: bold;
    font-size: 18px;
    border: 1px solid var(--dropdown-border-color);
    border-radius: 33px;
    box-shadow: 0 2px 2px 0 var(--dropdown-box-shadow);

    &:after {
      display: inline-block;
      margin-left: 25px;
      opacity: 0.4;
    }
  }

  .dropdown-toggle.morethanone {
    &:after {
      opacity: 1;
    }
  }

  .dropdown-menu {
    width: 270px;
    border-radius: 20px;
    max-height: 700px;
    overflow: hidden;
    color: var(--primary-text-color);
    background-color: var(--dropdown-bg-color);
    box-shadow: 0 2px 2px 0 var(--dropdown-box-shadow);

    .overflow {
      overflow: auto;
      max-height: 600px;
      border-radius: 20px;
      margin: -10px 0;
    }

    .dropdown-item {
      color: var(--primary-text-color);
    }

    .dropdown-item.selected {
      background-color: var(--theme-color);
      color: $white;
    }
  }

  .dropdown-menu a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-menu a:hover,
  .dropdown-menu a:focus {
    background-color: #c9fc00;
    color: $black;
  }

  .text {
    font-size: 14px;
    letter-spacing: -0.1px;
    margin-top: 7px;
    text-align: right;
  }

  .session-count {
    --paragraph-font-size: 0.75rem;
    padding-left: 1rem;
  }

  time {
    // moment uses this selector
    font-weight: normal;
    font-size: 1rem;
  }
}
