[data-theme='light'] {
  --theme-asurion-color: #8223d2;
  --theme-bg-color: #fff;
  --theme-text-color: #000;
  --theme-color: #9100da;
  --theme-color-invert: #000;
  --theme-third-color: #8223d2;
  --theme-third-color-invert: #fff;
  --primary-text-color: #000;
  --secondary-text-color: #333;
  --third-text-color: #000;
  --header-color: #090909;
  --greyish-brown: #595959;
  --dark-grey: #6e767d;
  --seperator-color: #020102;
  --separator-color-v2: #e0e0e0;
  --spinner-bg-color: rgba(255, 255, 255, 0.5);
  --textarea-bg-color: #fff;
  --textarea-outline-color--focus: #8223d2;
  --loading-skeleton-gradient-color: #ededf1;

  // Toggle
  --toggle-bg-color: rgba(2, 1, 2, 0.1);
  --toggle-text-color: #020102;

  // Subheader
  --dropdown-border-color: #b0b1b4;
  --dropdown-bg-color: #fff;
  --dropdown-box-shadow: #dfe5e8;
  --initiate-scan-button-text-color: #9100da;
  --initiate-scan-button-disabled-text-color: rgba(145, 0, 218, 0.5);
  --initiate-scan-button-error-text-color: #d91b3b;

  // CardButton
  --card-button-bg-color: #ffffff;
  --card-button-border-color: #000000;
  --card-button-text-color: #000000;

  //Devices
  --devices-icon-active: #9100da;
  --devices-icon-inactive: #96a1b1;
  --tile-bg-color: rgba(255, 255, 255, 0.9);
  --tile-border-color: #c0c1da;
  --tile-section-seprator: #e0e4ef;
  --bg-color-odd: rgba(237, 236, 237, 1);
  --bg-color-even: rgba(237, 236, 237, 0.5);
  --table-header-color: #e0e4ef;

  --disabled-background: #e6e6eb;
  --button-border-color--disabled: white;
  --button-color--disabled: white;
  --button-border-color--primary-ghost: #8223d2;
  --button-border-color--primary-ghost: #8223d2;
  --button-border-color--primary-ghost--hover: #6708b7;
  --button-border-color--primary-ghost--hover: #6708b7;
  --button-color--primary-ghost--hover: #6708b7;
  --paragraph-color: black;

  // Accordion Colors
  --chevron-fill-color: $black;
  --accordion-label-color: $black;
  --accordion-background-color: $white;
  --accordion-background-color-label-active: $white;
  --accordion-background-color-hover: #f3f3f3;
  --accordion-panel-background-color: $white;
  --accordion-border-color: rgb(224, 224, 224);

  // Wifi Signal
  --wifi-signal-base-color: #d5d8de;
  --best-wifi-color: #30cfa3;
  --better-wifi-color: #99e82d;
  --average-wifi-color: #ffb805;
  --weak-wifi-color: #ff0000;
}

[data-theme='dark'] {
  --theme-asurion-color: #8223d2;
  --theme-bg-color: #000;
  --theme-text-color: #fff;
  --theme-color: #cb5aff;
  --theme-color-invert: #8223d2;
  --theme-third-color: #fff;
  --theme-third-color-invert: #8223d2;
  --primary-text-color: #fff;
  --secondary-text-color: #edeced;
  --third-text-color: #b0b1b4;
  --header-color: #fff;
  --greyish-brown: #595959;
  --dark-grey: #6e767d;
  --seperator-color: #b0b1b4;
  --separator-color-v2: #cfcfcf;
  --spinner-bg-color: rgba(0, 0, 0, 0.5);
  --textarea-bg-color: #1a1a1a;
  --textarea-outline-color--focus: #cb5aff;
  --loading-skeleton-gradient-color: #626162;

  // Toggle
  --toggle-bg-color: rgba(176, 177, 180, 0.5);
  --toggle-text-color: #fff;

  // Subheader
  --dropdown-border-color: #313d4c;
  --dropdown-bg-color: #2f2e30;
  --dropdown-box-shadow: #020102;

  --initiate-scan-button-text-color: #fff;
  --initiate-scan-button-disabled-text-color: rgb(104, 100, 233, 0.5);
  --initiate-scan-button-error-text-color: #e8554c;

  // CardButton
  --card-button-bg-color: #000000;
  --card-button-border-color: #ffffff;
  --card-button-text-color: #ffffff;

  //Devices
  --devices-icon-active: #cb5aff;
  --devices-icon-inactive: #b0b1b4;
  --tile-bg-color: #020102;
  --tile-border-color: #313d4c;
  --tile-section-seprator: #313d4c;
  --bg-color-odd: rgba(237, 236, 237, 0.2);
  --bg-color-even: rgba(237, 236, 237, 0.3);
  --table-header-color: #edeced;

  --disabled-background: #7c7c7c;
  --button-border-color--primary-ghost: white;
  --button-border-color--primary-ghost: white;
  --button-border-color--primary-ghost--hover: white;
  --button-border-color--primary-ghost--hover: white;
  --button-color--primary-ghost--hover: white;
  --paragraph-color: white;

  // Accordion
  --chevron-fill-color: #fff;
  --accordion-label-color: $black;
  --accordion-background-color: $white;
  --accordion-background-color-label-active: #1a1a1a;
  --accordion-background-color-hover: #1a1a1a;
  --accordion-panel-background-color: rgb(0, 0, 0);
  --accordion-border-color: rgb(207, 207, 207);

  // Wifi Signal
  --wifi-signal-base-color: #b0b1b4;
  --best-wifi-color: #30cfa3;
  --better-wifi-color: #99e82d;
  --average-wifi-color: #ffb805;
  --weak-wifi-color: #ff0000;
}

$breakpoints: (
  'phone': 320px,
  'tablet': 768px,
  'desktop': 1024px,
) !default;

$black: #000;
$white: #fff;

$good: #30cfa3;
$moderate: #7ed70e;
$low: #f8d338;
$bad: #e8554c;
$default: #b8bec7;

$disabled-button-radius: #60676c;

$warning: #f9d51c;

// Bandwidth
$usage: #9100da;
$plan-max: #6864e9;
$max-offered: rgba(176, 177, 180, 0.54);

// Font Family
$primary-font: Apercu-Pro-Regular;
$primary-font-light: Apercu-Pro-Light;
$primary-font-mono: Apercu-Pro-Mono;
$secondary-font: Arial;

// Z-index
$z-index-spinner: 99999;
