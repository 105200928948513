.internet-service-provider {
  .isp-logo {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 60px;
    width: 120px;
    margin-bottom: 10px;
  }

  .isp-name {
    color: var(--secondary-text-color);
    font-weight: bold;
  }
}
