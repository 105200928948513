.expert-tip {
  font-size: 1em;
  
  .tip-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
  }

  .tip-box {
    flex: 1 300px;
    white-space: normal;
    padding: 0.5rem;
  }
}
